<template>
  <svg
    :width="this.width"
    :height="this.height"
    :viewBox="this.viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7396 0C28.204 0 35.0895 6.88548 35.0895 15.3482C35.0895 23.8126 28.204 30.6981 19.7396 30.6981H2.1973C1.27725 30.6981 0.488632 30.1286 0.136385 29.2944C-0.170299 28.462 0.0487607 27.4964 0.707692 26.883L5.92481 22.0146C4.91714 19.9537 4.38964 17.6737 4.38964 15.3482C4.38964 6.88548 11.2751 0 19.7396 0Z"
      fill="#7BB8DC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.0893 15.3487C35.0893 23.8132 28.2038 30.6986 19.7393 30.6986V0.00050354C28.2038 0.00050354 35.0893 6.88423 35.0893 15.3487Z"
      fill="#A4CBEC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.3182 19.735C26.3182 20.9618 25.3526 21.9274 24.1258 21.9274H15.3547C14.4767 21.9274 13.6881 21.4016 13.3376 20.5674C12.9871 19.735 13.1606 18.8132 13.8195 18.1999L18.8193 13.1562H15.3547C14.1262 13.1562 13.1606 12.1906 13.1606 10.9639C13.1606 9.73539 14.1262 8.77153 15.3547 8.77153H24.1258C25.0021 8.77153 25.7924 9.29727 26.1429 10.1315C26.4952 10.9639 26.3182 11.8839 25.661 12.499L20.6594 17.5427H24.1258C25.3526 17.5427 26.3182 18.5065 26.3182 19.735Z"
      fill="#FEFEFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.3181 19.735C26.3181 20.9618 25.3525 21.9274 24.1258 21.9274H19.7393V8.77153H24.1258C25.002 8.77153 25.7924 9.29727 26.1429 10.1315C26.4951 10.9639 26.3181 11.8839 25.6609 12.499L20.6594 17.5427H24.1258C25.3525 17.5427 26.3181 18.5065 26.3181 19.735Z"
      fill="#FEFEFE"
    />
  </svg>
</template>
<script>
export default {
    name: 'SleepApneaSvg',
    props: ['width', 'height', 'viewBox']
}
</script>