<template>
  <svg
    class="a-star"
    :viewBox="this.viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#FFAE19" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 23.3193L13.5402 25.664C12.8066 26.0497 11.9492 25.4267 12.0893 24.6098L12.941 19.6438L9.33297 16.1268C8.73945 15.5483 9.06696 14.5403 9.88718 14.4211L14.8734 13.6966L17.1032 9.17833C17.4701 8.43508 18.5299 8.43508 18.8967 9.17833L21.1266 13.6966L26.1128 14.4211C26.933 14.5403 27.2605 15.5483 26.667 16.1268L23.059 19.6438L23.9107 24.6098C24.0508 25.4267 23.1934 26.0497 22.4598 25.664L18 23.3193Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
    name: 'StarSvg',
    props: ['viewBox']
};
</script>