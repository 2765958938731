<template>
  <div class="col-6">
    <div class="a-info d-flex bg-w">
      <div class="a-info__img align-self-center">
        <HeartSvg :width="37" :height="34" :viewBox="'0 0 37 34'" />
      </div>
      <div class="a-info__detail align-self-center">
        <router-link class="btn-link" :to="{ path: 'pulse-ox' }">
          <div class="a-info__title">PulseOx</div>
          <div class="a-info__count">{{this.pulse}}%</div>
          <div class="a-info__count-title">0<span>2</span></div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import HeartSvg from '@/components/svg/HeartSvg'
export default {
  name: 'PulseOxCard',
  props: ['pulse'],
  components: {
    HeartSvg
  }
}
</script>