<template>
     <svg
          :width="this.width"
          :height="this.height"
          :viewBox="this.viewBox"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.3436 32.3936C8.22633 21.3488 -1.21949 14.4538 2.93339 6.36354C7.84935 -3.21462 18.3436 5.96228 18.3436 5.96228C18.3436 5.96228 28.8378 -3.21462 33.7537 6.36354C37.9066 14.4538 28.4614 21.3488 18.3436 32.3936Z"
            fill="#E0325C"
          />
          <path
            d="M18.3436 32.3936C8.22633 21.3488 -1.21949 14.4538 2.93339 6.36354C7.84935 -3.21462 18.3436 5.96228 18.3436 5.96228C18.3436 5.96228 28.8378 -3.21462 33.7537 6.36354C37.9066 14.4538 28.4614 21.3488 18.3436 32.3936"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7975 5.52011C15.5818 3.80589 7.17812 -1.90681 2.93339 6.36354C-0.448617 12.9521 5.18815 18.7478 12.8616 26.6378C14.4409 28.2617 16.1065 29.9742 17.7975 31.8007V5.52011Z"
            fill="#FF5B83"
          />
          <path
            d="M18.3436 32.3936C8.22633 21.3488 -1.21949 14.4538 2.93339 6.36354C7.84935 -3.21462 18.3436 5.96228 18.3436 5.96228C18.3436 5.96228 28.8378 -3.21462 33.7537 6.36354C37.9066 14.4538 28.4614 21.3488 18.3436 32.3936"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="37"
            height="34"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.3436 32.3936C8.22633 21.3488 -1.21949 14.4538 2.93339 6.36354C7.84935 -3.21462 18.3436 5.96228 18.3436 5.96228C18.3436 5.96228 28.8378 -3.21462 33.7537 6.36354C37.9066 14.4538 28.4614 21.3488 18.3436 32.3936Z"
              fill="white"
            />
            <path
              d="M18.3436 32.3936C8.22633 21.3488 -1.21949 14.4538 2.93339 6.36354C7.84935 -3.21462 18.3436 5.96228 18.3436 5.96228C18.3436 5.96228 28.8378 -3.21462 33.7537 6.36354C37.9066 14.4538 28.4614 21.3488 18.3436 32.3936"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </mask>
          <g mask="url(#mask0)"></g>
        </svg>
</template>
<script>
export default {
    name: 'HeartSvg',
    props: ['width', 'height', 'viewBox']
}
</script>