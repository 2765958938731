import Vue from "vue";
import App from "./App.vue";
import router from "./app/routes/routes";
import store from "./app/store";
import axios from "axios";
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

import AppPlugins from './plugins/app-plugins';

import moment from 'moment-timezone'
moment.tz.setDefault('Australia/Sydney')

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueScrollTo from 'vue-scrollto' 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


// register VueFusionCharts component
Vue.use(VueFusionCharts, FusionCharts, TimeSeries);
Vue.use(AppPlugins);
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(VueScrollTo, {
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
})


library.add(faPlus, faMinus)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//Vue.use(Vuelidate)
Vue.config.productionTip = false;
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";
Vue.use(Toaster, { timeout: 5000 });
axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("Logout");
      return router.push("/Login");
    }
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
