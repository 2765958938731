import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/app/pages/Home.vue";
import Welcome from "@/app/pages/Welcome.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/app/pages/auth/Login.vue"),
      meta: {
        disableIfLoggedIn: true,
      }
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/app/pages/auth/Register.vue"
      ),
    meta: {
      disableIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/app/pages/auth/ForgotPassword.vue"
      ),
    meta: {
      disableIfLoggedIn: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/app/pages/auth/ResetPassword.vue"
      ),
    meta: {
      disableIfLoggedIn: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-card",
    name: "AddCard",
    component: () =>
      import(/* webpackChunkName: "add-card" */ "@/app/pages/AddCard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/fruit",
    name: "Fruit",
    component: () =>
      import(/* webpackChunkName: "fruit" */ "@/app/pages/Fruit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "account" */ "@/app/pages/Account.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/test-data",
    name: "TestData",
    component: () =>
      import(/* webpackChunkName: "test-data" */ "@/app/pages/TestData.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pulse-ox",
    name: "PulseOx",
    component: () =>
      import(/* webpackChunkName: "pulse-ox" */ "@/app/pages/PulseOx"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sleep-apnea",
    name: "SleepApnea",
    component: () =>
      import(/* webpackChunkName: "sleep-apnea" */ "@/app/pages/SleepApnea"),
    meta: { requiresAuth: true },
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "@/app/pages/error/NotFound.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let access_token = localStorage.getItem("access_token");
  if (to.matched.some((record) => record.meta.disableIfLoggedIn)) {
    if (access_token != null) {
      next({ name: "Home" });
      return;
    }
    next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (access_token != null) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
