<template>
     <div class="a-block">
            <div class="row">
              <div class="col-12">
                <div class="a-info d-flex bg-w">
                  <div class="a-info__img align-self-center">
                   <SleepApneaSvg :width="36" :height="31" :viewBox="'0 0 36 31'" />
                  </div>
                  <div class="a-info__detail align-self-center">
                     <router-link :to="{ path: 'sleep-apnea' }">
                    <div class="a-info__title">Sleep Apnea</div>
                    <div class="a-info__count">{{apneaEvents > 1 ? apneaEvents: 'No '}} Events</div>
                    <div class="a-info__count-title">{{apneaEvents > 1 ? 'Last': 'in the past'}} 7 days</div>
                     </router-link>
                  </div>
                  <div class="a-info__graph align-self-center">
                  <SleepApneaGraphSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>
<script>
import SleepApneaSvg from '@/components/svg/SleepApneaSvg'
import SleepApneaGraphSvg from '@/components/svg/SleepApneaGraphSvg'
export default {
    props: ['apneaEvents'],
    components: {
      SleepApneaSvg,
      SleepApneaGraphSvg
    }
}
</script>