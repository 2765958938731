import Vue from "vue";
import Vuex from "vuex";
import VueAxios from "vue-axios";
import axios from "axios";
import axiosclient from "../../utils/axiosclient";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
 
};

const getters = {};

const mutations = {
//   setDailies: (state, payload) => {
//     state.dailies = payload;
//   },
  

};

const actions = {
  createTask() {
    return axiosclient
      .post('create-fred-task')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
    },
    checkTaskStatus() {
        return axiosclient
          .get('check-fred-task-status')
          .then((res) => {
            if (res.data) {
              return Promise.resolve(res.data);
            }
          })
          .catch(() => {
            return Promise.reject("Failed to connect");
          });
    }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
