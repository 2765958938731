<template>
  <div id="app">
    <router-view />
    <loading :active.sync="loading" 
        :can-cancel="true" 
        :color="color"
        :background-color="backGroundColor"
        :loader="loader"
        :is-full-page="true"></loading>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Pusher from "pusher-js";
import { garminPromptMixin } from "@/mixins/appMixins";
//import { vueTopprogress } from "vue-top-progress";
 import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  mixins: [garminPromptMixin],
  components: {
   Loading
  },
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return{
      color: 'white',
      backGroundColor: 'black',
      size: '10',
      loader: 'bars',
      sleepDataPushed: false
    }
  },
  // watch: {
  //   loading(val) {
  //     if (val === true) {
  //       this.$refs.topProgress.start();
  //     } else if (val === false) {
  //       this.$refs.topProgress.done();
  //     } else if (val === "error") {
  //       this.$refs.topProgress.fail();
  //     }
  //   },
  // },
  created() {
     let self=this;
    Pusher.logToConsole = true;
    let pusher = new Pusher("dd560d203fd5bbd16287", {
      cluster: "ap2",
      encrypted: false,
      secret: "",
      // options: {
      //  cluster: "ap2",
      // }
    });
    const channel = pusher.subscribe("newPulseOx");
    channel.bind("pusher:subscription_succeeded", function () {
      //  alert('connected')
    });

    channel.bind("pulse-ox-pushed", function () {
      alert("PulseOx pushed to server. Now you can go real-time!!");
    });

    const apneChannel = pusher.subscribe("newSleepApnea");
    channel.bind("pusher:subscription_succeeded", function () {
      //  alert('connected')
    });

    apneChannel.bind("sleep-apnea-pushed", function () {
      // alert("Sleep apnea pushed to server. Notification will be sent to fred about this event.!!");
     
       self.checkForSleepApnea();

    });
  },
  
  async mounted() {
    if (this.$auth.isAuthenticated()) {
      this.setIsAuthenticated({ auth: true, token: this.$auth.getToken() });
      await this.fetchUserInfoAction();
    } else {
      this.setIsAuthenticated({ auth: false });
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    ...mapActions(["setIsAuthenticated"]),
    ...mapActions("user", ["fetchUserInfoAction"]),
    ...mapActions("garmin", ["checkGarminStatus"]),
    ...mapActions("fred", ["createTask"]),
       ...mapActions(["setLoading"]),

  async checkForSleepApnea(){
    this.setLoading(true)
     let res = await this.createTask();
      this.setLoading(false)
      this.$toaster.success("MedView test task created with id " + res.data);
   }
  },
};
</script>

<style>
.eye-open {
  margin-left: 380px;
  margin-top: -90px;
}
.swal2-confirm.btn-success,
.btn-danger,
.btn-warning {
  border-radius: 4px;
  margin-right: 8px;
}
.form-control {
  border: 1px solid #dddddd;
  height: calc(1.4em + 1.5rem + 0px);
}
.VuePassword__Input {
  border: 1px solid #dddddd;
  height: calc(1.4em + 1.5rem + 0px);
  border-radius: 0.25rem !important;
}
.form-control:focus,
.VuePassword__Input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #16ad2f;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 148, 220, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 148, 220, 0.25);
}
.error-input {
  border-color: #f33b3b;
  outline: 3;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 148, 220, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(233, 64, 34, 0.25);
  background: "darkgrey" !important;
}
.swal2-popup {
  width: 25em !important;
}
.swal2-container.swal2-shown {
  position: absolute;
}
.cropper {
  margin-top: 9px;
}
.cropper .ml-auto {
  padding-left: 400px !important;
}
.cropper .label {
  display: inline;
}
.cropper .lable .input {
  width: 100%;
}
@media (max-width: 500px) {
  canvas {
    width: 100% !important;
    height: auto;
  }
  .cropper {
    margin-top: 0px;
  }
  .cropper .ml-auto {
    padding-left: 270px !important;
  }
  .cropper .label {
    display: inline;
  }
  .cropper .lable .input {
    width: 100%;
  }
  .flex-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader{
  background-color: rgba(0, 147, 220, 0.144);
    z-index: 9999 !important;
    width: 100%;
}

}
</style>
