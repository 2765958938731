<template>
  <div class="a-head__user">
    <div class="a-head__user-img">
      <div class="a-head__img-wrap" @click="showCropper = !showCropper">
        <img
          v-if="profile && profile.profile_pic_url"
          :src="profile.profile_pic_url"
        />
        <img v-else src="images/uploadpic.png" />
      </div>
      <div class="a-head__user-star">
        <StarSvg :viewBox="'0 0 36 36'" />
      </div>
    </div>
    <div class="a-head__user-name">
      {{ profile ? profile.first_name + " " + profile.last_name : null }}
    </div>
    <div class="a-head__user-address">Melbourne, Victoria</div>
    <Modal :show="showCropper">
      <div v-if="showCropper" class="cropper">
        <div class="img_box">
          <vue-avatar
            :width="350"
            :height="350"
            :rotation="rotation"
            :borderRadius="borderRadius"
            :scale="parseInt(scale)"
            ref="vueavatar"
            :accept="'image/*'"
            @vue-avatar-editor:image-ready="onImageReady"
          >
          </vue-avatar>
          <br />
          <label class="zoom">
            Zoom : {{ this.scale }}x
            <br />
            <input type="range" min="1" max="5" step="0.02" v-model="scale" />
          </label>
          <br />
          <div style="display: flex; justify-content: space-between">
            <button
              class="btn btn-sm btn-warning uploadbutton"
              type="button"
              v-on:click="onUploadProfilePic"
            >
              Upload
            </button>
            <a class="cancel" type="button" @click="showCropper = false">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { VueAvatar } from "vue-avatar-editor-improved";
import StarSvg from "@/components/svg/StarSvg";
import Modal from "@/components/Modal";
import axios from "axios";
import { mapActions, mapState } from "vuex";
export default {
  props: ["profile"],
  components: {
    StarSvg,
    VueAvatar,
    Modal,
  },
  data() {
    return {
      rotation: 0,
      scale: 1,
      borderRadius: 60,
      showCropper: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("user", ["fetchUserInfoAction"]),
    onUploadProfilePic() {
      var img = this.$refs.vueavatar.getImageScaled();
      img.toBlob((blob) => {
        const formData = new FormData();
        formData.append("profile_pic", blob, "avatar");
        this.setLoading(true);
        this.uploadProfileImage(formData);
      });
    },
    uploadProfileImage(formData) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.$auth.getToken(),
        },
      };
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/profile-picture",
          formData,
          config
        )
        .then((res) => {
          if (!res.error) {
            this.setLoading(false);
            this.fetchUserInfoAction();
            this.showCropper = !this.showCropper;
          }
        });
    },
    onImageReady() {
      this.scale = 1;
      this.rotation = 0;
    },
  },
};
</script>
<style scoped>
/* .modal-body canvas#avatarEditorCanvas {
    width: 100%;
    height: auto;
} */

.cancel {
  cursor: pointer;
  margin-top: 29px;
  color: orange;
}

</style>