<template>
     <div class="a-block">
            <div class="row">
              <div class="col-12">
                <div class="a-info d-flex bg-w">
                  <div class="a-info__img align-self-center">
                    <svg
                      width="24"
                      height="39"
                      viewBox="0 0 24 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.634 26.5361L9.8907 29.7928L7.07627 37.3917C6.75462 38.2361 5.9505 38.7588 5.10617 38.7588C4.86493 38.7588 4.62369 38.7186 4.38246 38.6381C3.29689 38.2361 2.734 37.0299 3.13606 35.9443L6.634 26.5361Z"
                        fill="url(#paint0_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.562877 18.2134L2.49277 14.5144C3.01545 13.5495 3.77937 12.7454 4.70411 12.1825C9.167 9.52887 9.72989 9.56907 10.2928 9.60928L13.3082 9.81031C14.233 9.85052 14.8763 10.0918 17.9722 14.6351C18.0928 14.7959 18.2536 14.9165 18.4948 14.9567L22.1938 15.5196C23.199 15.6804 23.8825 16.6052 23.7216 17.6103C23.5608 18.6155 22.6361 19.299 21.6309 19.1381L17.9319 18.5753C16.7258 18.3742 15.6402 17.7309 14.9567 16.7258C14.7959 16.4845 14.6752 16.2835 14.5144 16.0825L12.1423 23.601L15.4392 26.8979C15.9216 27.3804 16.3237 28.0237 16.5649 28.667L19.0979 36.065C19.5 37.1505 18.8969 38.3567 17.8113 38.7186C17.5701 38.799 17.3691 38.8392 17.1278 38.8392C16.2433 38.8392 15.4392 38.2763 15.1175 37.432L12.5845 30.034C12.5443 29.9536 12.5041 29.9134 12.4639 29.833L6.59381 23.9227C5.74948 23.0784 5.42783 21.9124 5.66906 20.7866L6.95566 15.0773C6.83504 15.1577 6.71442 15.2381 6.5536 15.3186C6.19174 15.5196 5.9103 15.8412 5.70927 16.2031L3.77937 19.9021C3.45772 20.5052 2.81442 20.867 2.17112 20.867C1.88968 20.867 1.60824 20.7866 1.32679 20.666C0.442259 20.1835 0.120609 19.0979 0.562877 18.2134Z"
                        fill="url(#paint1_linear)"
                      />
                      <circle
                        cx="12.4196"
                        cy="4.64431"
                        r="4.06071"
                        transform="rotate(-83.7883 12.4196 4.64431)"
                        fill="url(#paint2_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="3.0061"
                          y1="26.5361"
                          x2="3.0061"
                          y2="38.7588"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#26C8A8" />
                          <stop offset="1" stop-color="#00BBCA" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear"
                          x1="0.365662"
                          y1="9.59946"
                          x2="0.365662"
                          y2="38.8392"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#26C8A8" />
                          <stop offset="1" stop-color="#00BBCA" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear"
                          x1="8.3589"
                          y1="0.583599"
                          x2="8.3589"
                          y2="8.70501"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#26C8A8" />
                          <stop offset="1" stop-color="#00BBCA" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div class="a-info__detail align-self-center">
                    <div class="a-info__title">Wellbeing</div>
                    <div class="a-info__count">{{this.steps?this.steps:0}}</div>
                    <div class="a-info__count-title">steps</div>
                  </div>
                  <div class="a-info__graph align-self-center">
                    <svg
                      width="114"
                      height="76"
                      viewBox="0 0 114 76"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41 41.5C41 40.6716 41.6716 40 42.5 40C43.3284 40 44 40.6716 44 41.5V66.5C44 67.3284 43.3284 68 42.5 68C41.6716 68 41 67.3284 41 66.5L41 41.5Z"
                        fill="url(#paint0_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M59 23.5C59 22.6716 59.6716 22 60.5 22C61.3284 22 62 22.6716 62 23.5V66.5C62 67.3284 61.3284 68 60.5 68C59.6716 68 59 67.3284 59 66.5V23.5Z"
                        fill="url(#paint1_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M50 56.5C50 55.6716 50.6716 55 51.5 55C52.3284 55 53 55.6716 53 56.5V66.5C53 67.3284 52.3284 68 51.5 68C50.6716 68 50 67.3284 50 66.5L50 56.5Z"
                        fill="url(#paint2_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33 56.5C33 55.6716 33.6716 55 34.5 55C35.3284 55 36 55.6716 36 56.5V66.5C36 67.3284 35.3284 68 34.5 68C33.6716 68 33 67.3284 33 66.5L33 56.5Z"
                        fill="url(#paint3_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M77 36.5C77 35.6716 77.6716 35 78.5 35C79.3284 35 80 35.6716 80 36.5V66.5C80 67.3284 79.3284 68 78.5 68C77.6716 68 77 67.3284 77 66.5L77 36.5Z"
                        fill="url(#paint4_linear)"
                      />
                      <rect
                        x="68"
                        y="10"
                        width="3"
                        height="58"
                        rx="1.5"
                        fill="url(#paint5_linear)"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M86 21.5C86 20.6716 86.6716 20 87.5 20C88.3284 20 89 20.6716 89 21.5V66.5C89 67.3284 88.3284 68 87.5 68C86.6716 68 86 67.3284 86 66.5V21.5Z"
                        fill="url(#paint6_linear)"
                      />
                      <circle cx="51.5" cy="73.5" r="2.5" fill="#F75D34" />
                      <circle cx="34.5" cy="73.5" r="2.5" fill="#F75D34" />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="44.6027"
                          y1="52.7478"
                          x2="40.7905"
                          y2="52.3889"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2DA3EB" />
                          <stop offset="1" stop-color="#37CDA5" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear"
                          x1="62.6027"
                          y1="42.9428"
                          x2="58.7693"
                          y2="42.7231"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2DA3EB" />
                          <stop offset="1" stop-color="#37CDA5" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear"
                          x1="53.6027"
                          y1="60.9186"
                          x2="49.9086"
                          y2="60.1695"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#EB942D" />
                          <stop offset="1" stop-color="#CD3737" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear"
                          x1="36.6027"
                          y1="60.9186"
                          x2="32.9086"
                          y2="60.1695"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#EB942D" />
                          <stop offset="1" stop-color="#CD3737" />
                        </linearGradient>
                        <linearGradient
                          id="paint4_linear"
                          x1="80.6027"
                          y1="50.0242"
                          x2="76.7811"
                          y2="49.7189"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2DA3EB" />
                          <stop offset="1" stop-color="#37CDA5" />
                        </linearGradient>
                        <linearGradient
                          id="paint5_linear"
                          x1="71.6027"
                          y1="36.4061"
                          x2="67.7646"
                          y2="36.2317"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2DA3EB" />
                          <stop offset="1" stop-color="#37CDA5" />
                        </linearGradient>
                        <linearGradient
                          id="paint6_linear"
                          x1="89.6027"
                          y1="41.8533"
                          x2="85.7683"
                          y2="41.6428"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2DA3EB" />
                          <stop offset="1" stop-color="#37CDA5" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: 'WellBeingCard',
  props: ['steps']
}
</script>

