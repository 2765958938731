import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from 'moment-timezone'


export const logoutMixin = {
  methods: {
    logout() {
      swal({
        title: "Are you sure to logout?",
        text: `Please click Logout to proceed!`,
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Logout!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$auth.logout({}).then(() => {
            this.setIsAuthenticated({ auth: false });
            localStorage.removeItem("access_token")
            this.$router.go("/");
          });
        }
      });
    },
  },
};

export const garminPromptMixin = {
  methods: {
    promptGarminConnect() {
      if (localStorage.getItem("access_token")) {
        swal({
          title: "Connect to Garmin?",
          text: `Please click connect to proceed!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-warning btn-fill",
          confirmButtonText: "Connect!",
          cancelButtonText: "Later",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            if (this.profile) {
              window.location.replace(
                // "https://api.health-card.andmine.com/garmin-connect/" +  staging
                "http://api.healthcards.com.au/garmin-connect/" +     //live
                // "http://health.local/garmin-connect/" +             //local
                this.profile.id
              );
            }
          }
        });
      } else {
        localStorage.removeItem("access_token")
        this.$router.go("/");
      }
    }
  }
}

export const secondsToHmsMixins = {
  methods: {
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
  },
};

export const calendarDateFormat = {
  methods: {
    getDateMonthYear(d) {
      return moment(d).format("YYYY-MM-DD");
    },
    getSleepDateMonthYear(timeStamp, s) {
     // return moment(d).format("YYYY-MM-DD");
      return moment(moment.unix(timeStamp).format()).seconds(s).format("YYYY-MM-DD");
    },
  }
}

export const hourMinSec = {
  methods: {
    getHourMinute(seconds) {
      if (!seconds) return "";
      let duration = seconds;
      let hours = duration / 3600;
      duration = duration % 3600;

      let min = parseInt(duration / 60);
      duration = duration % 60;

      if (min < 10) {
        min = `0${min}`;
      }

      if (parseInt(hours, 10) > 0) {
        return `${parseInt(hours, 10)}h ${min}m`;
      } else {
        return `${min}m`;
      }
    }
  }
}
export const AmPm = {
  methods: {
    getDateAmPm(d, s) {
      return moment(d).startOf("day").seconds(s).format("hh:mm A");
    },
    getSleepDateAmPm(timeStamp, s) {

      return moment(moment.unix(timeStamp).format()).seconds(s).format("hh:mm A");
    },
    get24(d, s) {
      return moment(d).startOf("day").seconds(s).format("HH:mm");
    }  
  }
}

