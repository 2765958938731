import Vue from "vue";
import Vuex from "vuex";
import VueAxios from "vue-axios";
import axios from "axios";
import axiosclient from "../../utils/axiosclient";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  dailies: [],
  sleeps: [],
  pulseOx: [],
  pulseOxAverge: [],
  sleepApneaEvents: [],
  garminStatus: false
};

const getters = {};

const mutations = {
  setDailies: (state, payload) => {
    state.dailies = payload;
  },
  setSleepData: (state, payload) => {
    state.sleeps = payload;
  },
  setSleepApneaEvents: (state, payload) => {
    state.sleepApneaEvents = payload;
  },
  setPulseOx: (state, payload) => {
    state.pulseOx = payload;
  },
  setPulseOxAverage: (state, payload) => {
    state.pulseOxAverge = payload;
  },
  setGarminStatus: (state, payload) => {
    state.garminStatus = payload;
  },

};

const actions = {
  pullDailies({commit}) {
    return axiosclient
      .get('dailies')
      .then((res) => {
        if (res.data.data) {
          commit('setDailies', res.data.data)
          return Promise.resolve(res.data.data);
        }
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
  },

  pullSleep({commit}, payload) {
    return axiosclient
      .get('/sleeps/' + payload)
      .then((res) => {
        if (res.data) {
          commit('setSleepData', res.data)
          return Promise.resolve(res.data);
        }
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
  },
  pullSleepApneaEvents({commit}) {  
    return axiosclient
      .get('/sleep-apnea-events')
      .then((res) => {
        if (res.data) {
          commit('setSleepApneaEvents', res.data)
          return Promise.resolve(res.data);
        }
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
  },
  pullPulseOx({commit}, payload) {
    return axiosclient
      .get('/pulse-ox/' + payload)
      .then((res) => {
        if (res.data) {
          commit('setPulseOx', res.data)
          return Promise.resolve(res.data);
        }
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
  },
  pullPulseOxAverage({commit}, payload) {
    return axiosclient
      .get('/pulseox-average/' + payload)
      .then((res) => {
        commit('setPulseOxAverage', res.data)
        return Promise.resolve(res.data);
      })
      .catch(() => {
        return Promise.reject("Failed to connect");
      });
  },
  checkGarminStatus({ commit }) {
    return axiosclient
      .get('/garmin-status')
      .then((res) => {
        commit('setGarminStatus', res.data.data)
        return Promise.resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem('access_token')
        }
        return Promise.reject("Failed to connect");
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
