<template>
  <div
    class="collapse collapse--offcanvas-left navbar-collapse collapse is-transition-push"
    id="navbarNavAltMarkup"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
    @click="onSidebarClicked"
  >
    <div class="navbar-nav">
      <router-link class="nav-item nav-link active" :to="{ path: '/' }"
        >Home <span class="sr-only">(current)</span></router-link
      >
      <a
        class="nav-item nav-link"
        href="javascript:void(0)"
        @click="codeGarminClicked"
        >Code Garmin</a
      >
      <a
        class="nav-item nav-link"
        href="javascript:void(0)"
        @click="onCreateTaskClicked"
        >FRED Task Create</a
      >
      <a
        class="nav-item nav-link"
        href="javascript:void(0)"
        @click="checkTaskStatus"
      >
        FRED Task Status</a
      >
      <router-link class="nav-item nav-link" to="/account"
        >Account Settings</router-link
      >
      <a class="nav-item nav-link" href="javascript:void(0)" @click="logout"
        >Logout</a
      >
    </div>
    <button
      @click="menuCloseClicked"
      class="close-button"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { logoutMixin } from "@/mixins/appMixins";
export default {
  props: ["show"],
  mixins: [logoutMixin],
  methods: {
    ...mapActions(["setIsAuthenticated", "setLoading"]),
    ...mapActions("fred", ["createTask", "checkTaskStatus"]),
    codeGarminClicked() {
      this.$emit("codeGarmin");
    },
    menuCloseClicked() {
      this.$emit("menuCloseClicked");
    },
    onSidebarClicked() {
      this.$emit("sideBarClicked");
    },
    async onCreateTaskClicked() {
      this.setLoading(true)
      let res = await this.createTask();
      this.setLoading(false)
      this.$toaster.success("MedView test task created with id " + res.data);
    },
    onCheckTaskStatusClicked() {
      this.checkTaskStatus();
    },
  },
};
</script>
<style scoped>
.navbar-nav {
    font-size: 1.15rem;
    font-family: sans-serif;
}
.navbar-collapse {
  z-index: 200;
}
.navbar-nav .nav-link {
    color: #656dff !important;
}
</style>